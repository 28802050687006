<template>
  <div class="card card-custom gutter-b">
    <div id="quan_ly_khach_hang" class="card-header d-flex justify-content-between">
      <div class="card-title w-100">
        <div class="card-title">
          <h3 class="card-label">Quản lý khách hàng</h3>
        </div>
        <div class="card-title">
          <button class="btn btn-success font-weight-bold fa-pull-right mr-2" @click="exportExcelDSKHLenTuVan"
                  :class="{'spinner spinner-white spinner-left' : isExportDSKHTuVan}">
            <i v-if="!isExportDSKHTuVan" class="la la-file-excel"></i> Xuất excel DSKH lên tư vấn
          </button>
          <button class="btn btn-success font-weight-bold mr-2 fa-pull-right"
                  :class="{'spinner spinner-white spinner-left' : is_export_excel}"
                  @click="exportExcel">
            <i v-if="!is_export_excel" class="la la-file-excel"></i> Xuất excel
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="example">
        <!--    Bộ lọc cố định            -->
        <div class="row">
          <div class="form-group col-md-3">
            <label for="">Mã học viên, tên, SĐT, email</label>
            <div class="input-group">
              <input v-model="query.student" type="text" class="form-control"
                     placeholder="Nhập mã học viên, tên, SĐT, email"
                     aria-describedby="basic-addon2">
              <div class="input-group-append">
																<span class="input-group-text line-height-0 py-0">
																	<span class="svg-icon">
																		<svg xmlns="http://www.w3.org/2000/svg"
                                         width="24px" height="24px"
                                         viewBox="0 0 24 24" version="1.1">
																			<g stroke="none" stroke-width="1"
                                         fill="none" fill-rule="evenodd">
																				<rect x="0" y="0" width="24"
                                              height="24"></rect>
																				<path
                                            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                            fill="#000000" fill-rule="nonzero"
                                            opacity="0.3"></path>
																				<path
                                                                                     d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                                                                     fill="#000000"
                                                                                     fill-rule="nonzero"></path>
																			</g>
																		</svg>
																	</span>
																</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="">Level</label>
                            <el-select v-model="query.level" multiple clearable filterable
                                       class="w-100" collapse-tags
                                       placeholder="Chọn level">
                                <el-option
                                     v-for="item in levels"
                                     :key="item.value"
                                     :label="item.name"
                                     :value="item.value"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Thêm bộ lọc</label>
                            <el-select v-model="query.custom_filter" multiple clearable filterable
                                       class="w-100" collapse-tags
                                       @change="resetCustomData"
                                       placeholder="Thêm bộ lọc">
                                <el-option
                                     v-for="item in custom_filters"
                                     :key="item.value"
                                     :label="item.name"
                                     :value="item.value"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Cột hiển thị</label>
                            <el-select v-model="query.show_column" multiple clearable filterable
                                       class="w-100" collapse-tags
                                       @change="getShowColumn"
                                       placeholder="Cột hiển thị">
                                <el-option
                                     v-for="item in show_columns"
                                     :key="item.value"
                                     :label="item.name"
                                     :value="item.value"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <!--    Bộ lọc tùy chỉnh           -->
                <div class="row">
                    <div class="col-md-3" v-if="this.query.custom_filter.includes('khu-vuc-lam-viec-sale')">
                        <div class="form-group">
                          <label for="">Khu vực làm việc sale</label>
                            <el-select v-model="query.khu_vuc_sale" multiple clearable filterable
                                       class="w-100" collapse-tags
                                       @change="getListBranchSale"
                                       placeholder="Khu vực làm việc sale">
                                <el-option
                                     v-for="item in area_sales"
                                     :key="item.id"
                                     :label="item.name"
                                     :value="item.id"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-3" v-if="this.query.custom_filter.includes('khu-vuc-lam-viec-tvv')">
                        <div class="form-group">
                          <label for="">Khu vực làm việc TVV</label>
                            <el-select v-model="query.khu_vuc_tvv" multiple clearable filterable
                                       class="w-100" collapse-tags
                                       @change="getListBranchTVV"
                                       placeholder="Khu vực làm việc TVV">
                                <el-option
                                     v-for="item in area_tvvs"
                                     :key="item.id"
                                     :label="item.name"
                                     :value="item.id"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-3" v-if="this.query.custom_filter.includes('van-phong-lam-viec-sale')">
                        <div class="form-group">
                          <label for="">Văn phòng làm việc Sale</label>
                            <el-select v-model="query.branch_sale" multiple clearable filterable
                                       class="w-100" collapse-tags
                                       placeholder="Văn phòng làm việc Sale">
                                <el-option
                                     v-for="item in branch_sales"
                                     :key="item.id"
                                     :label="item.name_alias ? item.name_alias : '' "
                                     :value="item.id"
                                >
                                    <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-3" v-if="this.query.custom_filter.includes('van-phong-lam-viec-tvv')">
                        <div class="form-group">
                          <label for="">Văn phòng làm việc TVV</label>
                            <el-select v-model="query.branch_tvv" multiple clearable filterable
                                       class="w-100" collapse-tags
                                       placeholder="Văn phòng làm việc TVV">
                                <el-option
                                     v-for="item in branch_tvvs"
                                     :key="item.id"
                                     :label="item.name_alias"
                                     :value="item.id"
                                >
                                    <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-3" v-if="this.query.custom_filter.includes('trung-tam-hen')">
                        <div class="form-group">
                          <label for="">Trung tâm hẹn</label>
                            <el-select v-model="query.center" multiple clearable filterable
                                       class="w-100" collapse-tags
                                       placeholder="Trung tâm hẹn">
                                <el-option
                                     v-for="item in centers"
                                     :key="item.id"
                                     :label="item.name"
                                     :value="item.id"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-3" v-if="this.query.custom_filter.includes('ngay-len-tu-van')">
                        <div class="form-group">
                          <label for="">Ngày lên tư vấn</label>
                            <date-picker
                                 v-model="query.ngay_tu_van"
                                 format="DD-MM-YYYY"
                                 valueType="YYYY-MM-DD"
                                 type="date"
                                 range
                                 placeholder="Ngày lên tư vấn"
                                 @input="handleNgayLenTuVan"
                            ></date-picker>
                        </div>
                    </div>
                  <div class="col-md-3" v-if="this.query.custom_filter.includes('ngay-tao')">
                    <div class="form-group">
                      <label for="">Ngày tạo</label>
                      <date-picker
                          v-model="query.ngay_tao"
                          format="DD-MM-YYYY"
                          valueType="YYYY-MM-DD"
                          type="date"
                          range
                          placeholder="Ngày tạo"
                          @input="handleNgayTao"
                      ></date-picker>
                    </div>
                  </div>
                    <div class="col-md-3" v-if="this.query.custom_filter.includes('phan-loai')">
                        <div class="form-group">
                          <label for="">Phân loại</label>
                            <el-select v-model="query.phan_loai" multiple clearable filterable
                                       class="w-100" collapse-tags
                                       placeholder="Phân loại">
                                <el-option
                                     v-for="item in list_phan_loai"
                                     :key="item.value"
                                     :label="item.name"
                                     :value="item.value"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-3" v-if="this.query.custom_filter.includes('ngay-sinh')">
                        <div class="form-group">
                          <label for="">Ngày sinh</label>
                            <date-picker
                                 v-model="query.ngay_sinh"
                                 format="DD-MM-YYYY"
                                 valueType="YYYY-MM-DD"
                                 type="date"
                                 range
                                 placeholder="Ngày sinh"
                                 @input="handleNgaySinh"
                            ></date-picker>
                        </div>
                    </div>
                    <div class="col-md-3" v-if="this.query.custom_filter.includes('gioi-tinh')">
                        <div class="form-group">
                          <label for="">Giới tính</label>
                            <el-select v-model="query.gioi_tinh" multiple clearable filterable
                                       class="w-100" collapse-tags
                                       placeholder="Giới tính">
                                <el-option
                                     v-for="item in genders"
                                     :key="item.value"
                                     :label="item.name"
                                     :value="item.value"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="text-center w-100">
                        <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                                @click="searchData()">
                            <i class="fa fa-search"></i> Tìm kiếm
                        </button>
                        <button v-else type="button" disabled
                                class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                            Tìm kiếm
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body" style="overflow-x: scroll;padding-top: 0">
            <div class="row">
                <div class="example col-md-12">
                    <div class="example-preview table-responsive overflow-auto mb-15">
                        <table class="table table-vertical-center">
                            <thead>
                                <tr>
                                    <th style="white-space: nowrap" v-for="(column, key) in columns" :key="key" :class="column.tdClass" scope="col">{{ column.label }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in rows" :key="index">
                                    <td v-for="(column, key) in columns" :key="key" :class="column.tdClass">
                                        <span v-if="column.field == 'name'">
                                            <router-link
                                                :to="{ name: 'customer-crm-detail', params: { id : item.id }, query: {best_customer_id: item.best_customer_id}}"><span
                                                style="white-space: nowrap">{{
                                                item[column.field]
                                              }}</span></router-link>
                                        </span>
                  <span v-else-if="column.field == 'tien_da_dong' || column.field == 'tien_con_no'">{{
                      formatPrice(item[column.field], 'đ')
                    }}</span>
                  <span v-else>{{ item[column.field] }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style="display: flex;justify-content: center" class="col-md-12 edu-paginate mx-auto text-center">
          <paginate
              v-model="page"
              :page-count="last_page"
              :page-range="3"
              :margin-pages="2"
              :click-handler="clickCallback"
              :prev-text="'Trước'"
              :next-text="'Sau'"
              :container-class="'pagination b-pagination'"
              :pageLinkClass="'page-link'"
              :page-class="'page-item'"
              :next-link-class="'next-link-item'"
              :prev-link-class="'prev-link-item'"
              :prev-class="'page-link'"
              :next-class="'page-link'"
          >
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {GET_LIST_BRANCHES} from "../../../core/services/store/center/branch.module";
import {GET_LIST_AREAS} from "../../../core/services/store/center/area.module";
import {GET_LIST_CENTERS} from "../../../core/services/store/center/centers.module";
import {
  GET_CUSTOMER_CRM,
  EXPORT_CUSTOMER_CRM,
  EXPORT_DSKH_TU_VAN
} from "../../../core/services/store/customer/customer-crm.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {levels} from "@/core/option/customerOption";
import {exportExcel} from "../../../core/filters";

const _ = require('lodash');

export default {
    name: "CustomerCrm",
    components: {
        DatePicker, VueGoodTable
    },
    data() {
        return {
            levels: levels,
            custom_filters: [
                {value: 'khu-vuc-lam-viec-sale', name: 'Khu vực làm việc Sale'},
                {value: 'khu-vuc-lam-viec-tvv', name: 'Khu vực làm việc TVV'},
                {value: 'van-phong-lam-viec-sale', name: 'Văn phòng làm việc Sale'},
                {value: 'van-phong-lam-viec-tvv', name: 'Văn phòng làm việc TVV'},
                {value: 'trung-tam-hen', name: 'Trung tâm hẹn'},
                {value: 'ngay-len-tu-van', name: 'Ngày lên tư vấn'},
                {value: 'ngay-tao', name: 'Ngày tạo'},
                {value: 'phan-loai', name: 'Phân loại'},
                {value: 'ngay-sinh', name: 'Ngày sinh'},
                {value: 'gioi-tinh', name: 'Giới tính'},
            ],
            list_phan_loai: [
                {value: 'khach-hang-noi-bo', name: 'Khách hàng nội bộ'},
                {value: 'khach-hang-ngoai', name: 'Khách hàng ngoài'}
            ],
            genders: [
                {value: 'nu', name: 'Nữ'},
                {value: 'nam', name: 'Nam'}
            ],
            show_columns: [
                {value: 'ma-hoc-vien', name: 'Mã học viên' },
                {value: 'ngay-sinh', name: 'Ngày sinh'},
                {value: 'gioi-tinh', name: 'Giới tính'},
                {value: 'level', name: 'Level'},
                {value: 'phan-loai', name: 'Phân loại'},
                {value: 'so-tien-da-nop', name: 'Số tiền đã nộp'},
                {value: 'so-tien-no', name: 'Số tiền nợ'},
                {value: 'nguoi-gioi-thieu', name: 'Người giới thiệu'},
                {value: 'nguoi-tu-van', name: 'Người tư vấn'},
                {value: 'hinh-thuc-tu-van', name: 'Hình thức tư vấn'},
                {value: 'ngay-tao', name: 'Ngày tạo'},
                {value: 'khu-vuc-lam-viec-sale', name: 'Khu vực làm việc Sale'},
                {value: 'khu-vuc-lam-viec-tvv', name: 'Khu vực làm việc TVV'},
                {value: 'van-phong-lam-viec-sale', name: 'Văn phòng làm việc Sale'},
                {value: 'van-phong-lam-viec-tvv', name: 'Văn phòng làm việc TVV'},
                {value: 'trung-tam-hen', name: 'Trung tâm hẹn'},
                {value: 'ngay-len-tu-van', name: 'Ngày lên tư vấn'},
            ],
            area_sales: [],
            area_tvvs: [],
            branch_sales: [],
            branch_tvvs: [],
            centers: [],
            is_disable_search: false,
            isLoading: false,
            page: 1,
            last_page: 1,
            columns: [],
            rows: [],
            serverParams: {},
            is_export_excel: false,
            query: {
                student: '',
                level: [],
                custom_filter: [],
                show_column: ['level', 'so-tien-da-nop', 'so-tien-no', 'nguoi-gioi-thieu', 'nguoi-tu-van', 'ngay-tao'],
                khu_vuc_sale: [],
                khu_vuc_tvv: [],
                branch_sale: [],
                branch_tvv: [],
                center: [],
                ngay_tu_van: [],
                ngay_tao: [],
                phan_loai: [],
                ngay_sinh: [],
                gioi_tinh: [],
            },
            isExportDSKHTuVan: false,
        }
    },
    methods: {
        getShowColumn() {
            let columns = [
                {
                    label: 'STT',
                    field: 'stt',
                    tdClass: 'text-center',
                },
                {
                    label: 'Họ và tên',
                    field: 'name',
                    tdClass: 'text-center',
                },
                {
                    label: 'SĐT',
                    field: 'phone',
                    tdClass: 'text-center',
                }
            ];
            if(this.query.show_column.includes('ma-hoc-vien')) {
                columns.push({field: 'user_code', label: 'Mã học viên', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('ngay-sinh')) {
                columns.push({field: 'birthday', label: 'Ngày sinh', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('gioi-tinh')) {
                columns.push({field: 'gender', label: 'Giới tính', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('level')) {
                columns.push({field: 'level', label: 'Level', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('phan-loai')) {
                columns.push({field: 'phan_loai', label: 'Phân loại', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('so-tien-da-nop')) {
                columns.push({field: 'tien_da_dong', label: 'Số tiền đã nộp', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('so-tien-no')) {
                columns.push({field: 'tien_con_no', label: 'Số tiền nợ', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('nguoi-gioi-thieu')) {
                columns.push({field: 'ten_nguoi_gioi_thieu', label: 'Người giới thiệu', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('nguoi-tu-van')) {
                columns.push({field: 'ten_nguoi_tu_van', label: 'Người tư vấn', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('hinh-thuc-tu-van')) {
                columns.push({field: 'hinh_thuc_tu_van', label: 'Hình thức tư vấn', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('ngay-tao')) {
                columns.push({field: 'created_at', label: 'Ngày tạo', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('khu-vuc-lam-viec-sale')) {
                columns.push({field: 'khu_vuc_sale', label: 'Khu vực Sale', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('khu-vuc-lam-viec-tvv')) {
                columns.push({field: 'khu_vuc_tvv', label: 'Khu vực TVV', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('van-phong-lam-viec-sale')) {
                columns.push({field: 'chi_nhanh_sale', label: 'Văn phòng Sale', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('van-phong-lam-viec-tvv')) {
                columns.push({field: 'chi_nhanh_tvv', label: 'Văn phòng TVV', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('trung-tam-hen')) {
                columns.push({field: 'trung_tam_hen', label: 'Trung tâm hẹn', tdClass: 'text-center'});
            }
            if(this.query.show_column.includes('ngay-len-tu-van')) {
                columns.push({field: 'thoi_gian_khach_hang_den', label: 'Ngày lên tư vấn', tdClass: 'text-center'});
            }
            this.columns = columns;
        },
        onPageChange(params) {
            let payload = {
                'page': params.currentPage,
                'currentPerPage': params.currentPerPage,
            };
            this.searchData(payload);
        },
        onPerPageChange(params) {
            let payload = params || {};
            this.searchData(payload);
        },
        handleNgayLenTuVan($event) {
            let start = $event[0];
            let end = $event[1];
            if (!start || !end) {
                this.query.ngay_tu_van = [];
            }
        },
        handleNgayTao($event) {
            let start = $event[0];
            let end = $event[1];
            if (!start || !end) {
                this.query.ngay_tao = [];
            }
        },
        handleNgaySinh($event) {
            let start = $event[0];
            let end = $event[1];
            if (!start || !end) {
                this.query.ngay_sinh = [];
            }
        },
        getListArea() {
            this.$store.dispatch(GET_LIST_AREAS, {}).then(res => {
                if (!res.error) {
                    this.query.khu_vuc_sale = [];
                    this.query.khu_vuc_tvv = [];
                    this.area_sales = res.data;
                    this.area_tvvs = res.data;
                }
            })
        },
        getListBranchSale() {
            let payload = {
                'area_id': this.query.khu_vuc_sale
            };
            this.$store.dispatch(GET_LIST_BRANCHES, payload).then(res => {
                if (!res.error) {
                    this.query.branch_sale = [];
                    this.branch_sales = res.data;
                }
            })
        },
        getListBranchTVV() {
            let payload = {
                'area_id': this.query.khu_vuc_tvv
            };
            this.$store.dispatch(GET_LIST_BRANCHES, payload).then(res => {
                if (!res.error) {
                    this.query.branch_tvv = [];
                    this.branch_tvvs = res.data;
                }
            });
        },
        getListCenter() {
            this.$store.dispatch(GET_LIST_CENTERS, {}).then(res => {
                if (!res.error) {
                    this.query.center = [];
                    this.centers = res.data;
                }
            })
        },
        resetCustomData() {
            if(!this.query.custom_filter.includes('khu-vuc-lam-viec-sale')) {
                this.query.khu_vuc_sale = [];
            }
            if(!this.query.custom_filter.includes('khu-vuc-lam-viec-tvv')) {
                this.query.khu_vuc_tvv = [];
            }
            if(!this.query.custom_filter.includes('van-phong-lam-viec-sale')) {
                this.query.branch_sale = [];
            }
            if(!this.query.custom_filter.includes('van-phong-lam-viec-tvv')) {
                this.query.branch_tvv = [];
            }
            if(!this.query.custom_filter.includes('trung-tam-hen')) {
                this.query.center = [];
            }
            if(!this.query.custom_filter.includes('ngay-len-tu-van')) {
                this.query.ngay_tu_van = [];
            }
            if(!this.query.custom_filter.includes('ngay-tao')) {
                this.query.ngay_tao = [];
            }
            if(!this.query.custom_filter.includes('phan-loai')) {
                this.query.phan_loai = [];
            }
            if(!this.query.custom_filter.includes('ngay-sinh')) {
                this.query.ngay_sinh = [];
            }
            if(!this.query.custom_filter.includes('gioi-tinh')) {
                this.query.gioi_tinh = [];
            }
        },
        searchData(payload = {}) {
            payload = { ...payload, ...this.query };
            this.is_disable_search = true;
            this.$store.dispatch(GET_CUSTOMER_CRM, payload).then(res => {
                if (!res.error) {
                    this.rows = res.data;
                    this.last_page = res.pagination.last_page;
                } else {
                    this.$bvToast.toast(res.data.message, {
                        title: 'Errors',
                        variant: 'danger',
                        solid: true
                    });
                }
            }).finally(() => {
                this.is_disable_search = false;
            });
        },
        exportExcel() {
            this.is_export_excel = true
            let payload = this.query;
            const HEADER_ROW_EXPORT_CUSTOMER_LIST = [
                {
                    key: 'stt',
                    value: 'STT',
                },
                {
                    key: 'name',
                    value: 'Họ và tên',
                },
                {
                    key: 'phone',
                    value: 'SĐT',
                },
                {
                    key: 'id',
                    value: 'Mã học viên',
                },
                {
                    key: 'birthday',
                    value: 'Ngày sinh',
                },
                {
                    key: 'gender',
                    value: 'Giới tính',
                },
                {
                    key: 'level',
                    value: 'Level',
                },
                {
                    key: 'phan_loai',
                    value: 'Phân loại',
                },
                {
                    key: 'tien_da_dong',
                    value: 'Số tiền đã nộp',
                },
                {
                    key: 'tien_phai_dong',
                    value: 'Số tiền nợ',
                },
                {
                    key: 'sale_id',
                    value: 'Mã người giới thiệu',
                },
                {
                    key: 'sale_name',
                    value: 'Người giới thiệu',
                },
                {
                    key: 'tvv_id',
                    value: 'Mã người tư vấn',
                },
                {
                    key: 'tvv_name',
                    value: 'Người tư vấn',
                },
                {
                    key: 'ngay_tao',
                    value: 'Ngày tạo',
                },
                {
                    key: 'khu_vuc_sale',
                    value: 'Khu vực Sale',
                },
                {
                  key: 'khu_vuc_tvv',
                  value: 'Khu vực TVV',
                },
                {
                  key: 'sale_branch',
                  value: 'Văn phòng Sale',
                },
                {
                  key: 'tvv_branch',
                  value: 'Văn phòng TVV',
                },
                {
                  key: 'trung_tam_hen',
                  value: 'Trung tâm hẹn',
                },
                {
                  key: 'up_3b_time',
                  value: 'Ngày lên tư vấn',
                },
                {
                  key: 'address',
                  value: 'Địa chỉ',
                },
            ]
            this.$store.dispatch(EXPORT_CUSTOMER_CRM, payload).then((res) => {
                exportExcel(HEADER_ROW_EXPORT_CUSTOMER_LIST, res.data, "Danh sách khách hàng.xlsx", true)
                this.is_export_excel = false
            });
        },
        clickCallback(obj) {
            this.page = obj
            this.searchData({ page : this.page });
        },
        exportExcelDSKHLenTuVan() {
            this.isExportDSKHTuVan = true
            const HEADER_ROW_EXPORT_DSKH_TUVAN = [
                {
                    key: 'stt',
                    value: 'STT',
                },
                {
                    key: 'name',
                    value: 'Họ và tên',
                },
                {
                    key: 'phone',
                    value: 'Số điện thoại',
                },
                {
                    key: 'level',
                    value: 'Level sale',
                },
                {
                    key: 'created_at',
                    value: 'Ngày lên tư vấn',
                },
                {
                    key: 'user_intro_id',
                    value: 'Mã giới thiệu',
                },
                {
                    key: 'nguoi_tu_van',
                    value: 'Mã tư vấn',
                },
            ]
            this.$store.dispatch(EXPORT_DSKH_TU_VAN, {
                dateRange: this.query.ngay_tu_van
            }).then((res) => {
                exportExcel(HEADER_ROW_EXPORT_DSKH_TUVAN, res.data, "Danh sách khách hàng lên tư vấn.xlsx", true)
                this.isExportDSKHTuVan = false
            })
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Khách hàng", route: 'customer-user'},
            {title: "Quản lý khách hàng"}
        ]);
        this.searchData();
        this.getListCenter();
        this.getShowColumn();
        this.getListArea();
        this.getListBranchSale();
        this.getListBranchTVV();
    },
}
</script>
<style>
.example .example-preview {
    border: 4px solid #F3F6F9;
    padding: 0;
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem;
}
#quan_ly_khach_hang .card-title{
    justify-content: space-between;
}
</style>